<template>
  <section class="bg pg-height pd-20">
    <aForm
      class="form-contracts"
      @submit.prevent="submitOpportunity"
      @onFieldsChange="updateTempOpportunity"
      :form="opportunityForm"
    >
      <OptFunnelSteps
        v-if="tempOpportunity.id != undefined"
        :tempOpportunity="tempOpportunity"
        @reloadHistory="reloadHistory"
        ref="OptFunnelSteps"
      />

      <aRow class="mt-20" type="flex" align="top" :gutter="15">
        <aCol class="sidebar">
          <aRow :gutter="[20, 0]">
            <aCol class="mb-20" :span="24">
              <div class="w-box">
                <OptBasicInfos
                  v-if="tempOpportunity.id != undefined"
                  :tempOpportunity="tempOpportunity"
                />
              </div>
            </aCol>

            <aCol class="mb-20" :span="24">
              <div class="w-box">
                <OptCustomerBasicInfos
                  v-if="tempOpportunity.id != undefined"
                  :tempOpportunity="tempOpportunity"
                  @activeTab="activeTab"
                />
              </div>
            </aCol>

            <aCol class="" :span="24">
              <OptMenuSection
                v-if="tempOpportunity.id != undefined"
                :tempOpportunity="tempOpportunity"
                :opportunityForm="opportunityForm"
                :opportunity="opportunity"
                @activeTab="activeTab"
              />
            </aCol>

            <aCol class="mb-20" :span="24">
              <aRow :gutter="20">
                <aCol :span="18">
                  <a-button
                    v-if="tempOpportunity.status !== 'Ganho'"
                    class="a-center create-contract"
                    type="primary"
                    style="width: 100%"
                    :loading="loadingSubmitOpportunity"
                    :disabled="
                      tempOpportunity.funnel_step.allow_contract == 0
                        ? true
                        : false
                    "
                    @click="createContract"
                  >
                    CRIAR CONTRATO
                  </a-button>

                  <a-button
                    v-if="tempOpportunity.status === 'Ganho'"
                    class="a-center create-contract"
                    type="primary"
                    style="width: 100%"
                    :disabled="
                      tempOpportunity.contract_id != null &&
                      tempOpportunity.contract_id != undefined &&
                      tempOpportunity.contract_id != 0 &&
                      tempOpportunity.contract_id != ''
                        ? false
                        : true
                    "
                    @click="
                      $router.push(
                        `/contracts/edit/${tempOpportunity.contract_id}`
                      )
                    "
                  >
                    <a-icon type="edit" /> EDITAR CONTRATO
                  </a-button>
                </aCol>

                <aCol :span="6">
                  <a-button
                    class="a-center save"
                    type="primary"
                    html-type="submit"
                    style="width: 100%"
                    :loading="loadingSubmitOpportunity"
                    :disabled="false"
                  >
                    <img
                      src="@/assets/images/dashboard/contracts/save.png"
                      alt="save"
                    />
                  </a-button>
                </aCol>
              </aRow>
            </aCol>
          </aRow>
        </aCol>

        <aCol class="content">
          <div class="w-box">
            <div class="tab-icons">
              <div
                v-for="(item, index) in tabs"
                :key="index"
                class="tab grayscale"
                :class="activeOpportunityTab == item.name ? 'active' : ''"
                @click="optActiveTab(item.name)"
              >
                <img :src="item.img" alt="img" width="35" />
              </div>
            </div>

            <div class="tab-content">
              <aRow>
                <aCol
                  :span="24"
                  v-if="activeOpportunityTab == 'Dados do cliente'"
                >
                  <div class="title">
                    <h3>Dados do cliente</h3>
                  </div>

                  <OptCustomerDataTab
                    :tempOpportunity="tempOpportunity"
                    :opportunityForm="opportunityForm"
                    :fieldSizes="{
                      companyName: 24,
                      tradingName: 12,
                      cnpj: 12,
                      firstName: 24,
                      lastName: 12,
                      email: 24,
                      birthday: 12,
                      cpf: 12,
                      rg: 10,
                      rgEmissor: 8,
                      rgState: 6,
                      mobilePhone: 12,
                      phone: 12,
                      status: 12,
                    }"
                    @updateFunnelSteps="updateFunnelSteps"
                  />
                </aCol>

                <aCol
                  :span="24"
                  v-show="activeOpportunityTab == 'Dados da oportunidade'"
                >
                  <div class="title">
                    <h3>Dados da oportunidade</h3>
                  </div>
                  <OptDataTab
                    :tempOpportunity="tempOpportunity"
                    :opportunityForm="opportunityForm"
                    @updateFunnelSteps="updateFunnelSteps"
                  />
                </aCol>

                <aCol
                  :span="24"
                  v-if="activeOpportunityTab == 'Dados da solicitação'"
                >
                  <div class="title">
                    <h3>Dados da solicitação</h3>
                  </div>
                  <OptCustomFieldsTab
                    v-if="tempOpportunity.id != undefined"
                    :tempOpportunity="tempOpportunity"
                    :opportunityForm="opportunityForm"
                  />
                </aCol>

                <aCol :span="24" v-if="activeOpportunityTab == 'Arquivos'">
                  <div class="title">
                    <h3>Arquivos</h3>
                  </div>
                  <OptFilesTab
                    v-if="tempOpportunity.id != undefined"
                    :tempOpportunity="tempOpportunity"
                    :opportunityForm="opportunityForm"
                    @emitUpdateTempOpportunity="emitUpdateTempOpportunity"
                  />
                </aCol>

                <aCol :span="24" v-if="activeOpportunityTab == 'Histórico'">
                  <div class="title">
                    <h3>Histórico</h3>
                  </div>

                  <MultiLogsTimeline
                    class="mt-10"
                    :opportunityId="tempOpportunity.id"
                    :contractId="tempOpportunity.contract_id"
                    :saleId="tempOpportunity.sale_id"
                  />
                </aCol>

                <aCol :span="24" v-if="activeOpportunityTab == 'Whatsapp'">
                  <div class="title">
                    <h3>Whatsapp</h3>
                  </div>

                  <WhatsappConversations
                    v-if="tempOpportunity.mobile_phone"
                    :countryCode="55"
                    :phoneNumber="tempOpportunity.mobile_phone"
                    :customerName="tempOpportunity.customer_name"
                  />
                </aCol>

                <aCol :span="24" v-if="activeOpportunityTab == 'Notas'">
                  <div class="title">
                    <h3>Notas</h3>
                  </div>

                  <Notes
                    listType="multi"
                    moduleName="opportunity"
                    :moduleId="`${tempOpportunity.id}`"
                    :multiIds="[
                      {
                        module: 'opportunity',
                        id: tempOpportunity.id,
                      },
                      {
                        module: 'contract',
                        id: tempOpportunity.contract_id,
                      },
                      {
                        module: 'sale',
                        id: tempOpportunity.sale_id,
                      },
                    ]"
                    :noteReasonsCategoryid="21"
                    :entry="tempOpportunity"
                  />
                </aCol>

                <aCol
                  :span="24"
                  v-if="activeOpportunityTab == 'JSON da oportunidade'"
                >
                  <div class="title">
                    <h3>JSON da oportunidade</h3>
                  </div>

                  <json-viewer
                    class="mt-10 mb-20"
                    theme="json-viewer"
                    :value="opportunityJson"
                    :show-array-index="false"
                    :expand-depth="2"
                  ></json-viewer>
                </aCol>
              </aRow>
            </div>
          </div>
        </aCol>
      </aRow>
    </aForm>

    <div id="live-debug" class="f12 mb-30">
      <json-viewer
        v-if="$store.state.userData.id == 1"
        class="mt-10 mb-20"
        :value="tempOpportunity"
        :show-array-index="false"
        :expand-depth="1"
      ></json-viewer>
    </div>

    <a-tooltip v-if="tempOpportunity.theCustomer" placement="left">
      <template slot="title">
        <span>Clique aqui para falar com o cliente</span>
      </template>
      <a-button
        @click="openWhatsapp(tempOpportunity)"
        id="whatsapp"
        shape="round"
        ><i class="fab fa-whatsapp"></i
      ></a-button>
    </a-tooltip>
  </section>
</template>

<script>
import { format } from "date-fns";

import OptFunnelSteps from "@/components/pipeline/sections/OptFunnelSteps.vue";
import opportunityMixins from "@/components/pipeline/mixins/opportunityMixins";
import MultiLogsTimeline from "@/components/logs/MultiLogsTimeline.vue";
import OptBasicInfos from "@/components/pipeline/sections/OptBasicInfos.vue";
import OptCustomerBasicInfos from "@/components/pipeline/sections/OptCustomerBasicInfos.vue";
import OptMenuSection from "@/components/pipeline/sections/OptMenuSection.vue";
import Notes from "@/components/general/Notes.vue";
import OptDataTab from "@/components/pipeline/tabs/OptDataTab.vue";
import OptCustomerDataTab from "@/components/pipeline/tabs/OptCustomerDataTab.vue";
import OptCustomFieldsTab from "@/components/pipeline/tabs/OptCustomFieldsTab.vue";
import OptFilesTab from "@/components/pipeline/tabs/OptFilesTab.vue";
import WhatsappConversations from "@/components/general/WhatsappConversations.vue";

// images
import note from "@/assets/images/dashboard/opportunities/notes.png";
import logs from "@/assets/images/dashboard/opportunities/log.png";
import files from "@/assets/images/dashboard/opportunities/files.png";
import whatsapp from "@/assets/images/dashboard/opportunities/whatsapp.png";
import form from "@/assets/images/dashboard/opportunities/form.png";
import opt from "@/assets/images/dashboard/opportunities/opt.png";
import customerIco from "@/assets/images/dashboard/opportunities/customer-ico.png";

export default {
  name: "OpportunityPage",
  components: {
    OptFunnelSteps,
    MultiLogsTimeline,
    OptBasicInfos,
    OptCustomerBasicInfos,
    OptMenuSection,
    OptDataTab,
    OptCustomerDataTab,
    OptCustomFieldsTab,
    OptFilesTab,
    Notes,
    WhatsappConversations,
  },
  mixins: [opportunityMixins],
  data() {
    return {
      opportunityForm: this.$form.createForm(this, {
        onValuesChange: this.updateTempOpportunity,
      }),
      loadingSubmitOpportunity: false,
      activeOpportunityTab: "Dados da oportunidade",

      sales: [],
      tabs: [
        {
          name: "Dados da oportunidade",
          img: opt,
          ico: "fire",
          showItem: true,
          disabled: false,
          extraClass: "",
        },
        {
          name: "Dados do cliente",
          img: customerIco,
          ico: "",
          showItem: true,
          disabled: false,
          extraClass: "",
        },
        {
          name: "Dados da solicitação",
          img: form,
          ico: "",
          showItem: true,
          disabled: false,
          extraClass: "",
        },
        {
          name: "Notas",
          img: note,
          ico: "",
          showItem: true,
          disabled: false,
          extraClass: " no-border ",
        },
        {
          name: "Whatsapp",
          img: whatsapp,
          ico: "",
          showItem: true,
          disabled: false,
          extraClass: "",
        },
        {
          name: "Arquivos",
          img: files,
          ico: "",
          showItem: true,
          disabled: false,
          extraClass: "",
        },

        {
          name: "Histórico",
          img: logs,
          ico: "",
          showItem: true,
          disabled: false,
          extraClass: " no-border ",
        },
      ],
    };
  },
  mounted() {
    this.getOpportunity(this.$route.params.id);
  },
  methods: {
    optActiveTab(active) {
      setTimeout(() => {
        this.activeOpportunityTab = active;
      }, 100);
    },
    updateCustomer() {
      let updtCustomer = this.tempOpportunity.theCustomer;
      updtCustomer.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };
      this.$http.post("/customer/update", updtCustomer);
    },
    createContract() {
      let contractedServices = [],
        hotel = [],
        flight = [],
        service = [],
        extraContractFields = {},
        activeTab = "";

      Object.keys(this.tempOpportunity).forEach((key) => {
        if (key.includes("hotel")) hotel.push(key);
        if (key.includes("flight")) flight.push(key);
        if (key.includes("service")) service.push(key);
      });

      if (service.length > 0) {
        contractedServices.push("service");
        activeTab = "service";
        extraContractFields = {
          service_rows: '[{"id":1,"travellers":[{"id":1}]}]',
        };
      }

      if (flight.length > 0) {
        contractedServices.push("flight");
        activeTab = "flight";
        extraContractFields = {
          flight_sections_rows:
            '[{"id":1,"travellers":[{"id":1}],"airlines":[{"id":1}],"sections":[{"id":1}],"ticketed":false}]',
        };
      }

      if (hotel.length > 0) {
        contractedServices.push("hotel");
        activeTab = "hotel";
        extraContractFields = {
          hotel_rows:
            '[{"id":1,"accommodation":[{"id":1,"travellers":[{"id":1}]}]}]',
        };
      }

      let theCompany = "";

      if (this.tempOpportunity.company_id == 1) theCompany = "FEDERAL";
      if (this.tempOpportunity.company_id == 2) theCompany = "PRUDENTE";
      if (this.tempOpportunity.company_id == 3) theCompany = "BANDEIRANTES";

      let newContract = {
        opportunity_id: this.tempOpportunity.id,
        customer_id: this.tempOpportunity.customer_id,
        company_id: this.tempOpportunity.company_id,
        company: theCompany,
        company_branch_id: this.tempOpportunity.company_branch_id,
        id_infotera: "0",
        user: this.tempOpportunity.user_id,
        //  company: this.tempOpportunity.company.trading_name,
        value: "0.00",
        value_to_pay: "0.00",
        taxes_value: "0.00",
        profit_value: "0.00",
        profit_percentage: "0.00",
        financial_cost: "0.00",
        package_type: "Serviços",
        contracted_services:
          contractedServices.length > 0
            ? JSON.stringify(contractedServices)
            : "[]",
        products_name: "",
        marketing_types: "",
        first_checkin: "0000-00-00",
        last_checkout: "0000-00-00",
        release_date: "0000-00-00",
        status: "edit",
        ...this.tempOpportunity.meta,
        payment_methods: "[]",
        ...extraContractFields,
        active_tab: activeTab,
      };

      if (
        this.tempOpportunity.company_id &&
        this.tempOpportunity.company_branch_id
      ) {
        this.opportunityForm.validateFields((err) => {
          if (!err) {
            this.loadingSubmitOpportunity = true;

            this.updateCustomer();
            this.$http
              .post("/contract-v2/create", newContract)
              .then(({ data }) => {
                const contractId = data.id;
                this.tempOpportunity.contract_id = contractId;
                this.tempOpportunity.status = "Ganho";
                this.tempOpportunity.release_date = format(
                  new Date(),
                  "yyyy/MM/dd"
                );

                this.$http.post("/log/create", {
                  user_id: this.$store.state.userData.id,
                  module_id: this.tempOpportunity.id,
                  module: "opportunity",
                  action: "create-contract",
                  description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} criou o contrato ID ${data.id} a partir da oportunidade ID ${this.tempOpportunity.id}.`,
                });

                this.$tabs.close(
                  `/opportunity/edit/${this.tempOpportunity.id}`,
                  `/contracts/edit/${contractId}`
                );

                this.tempOpportunity.modified_by = {
                  name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                  id: this.$store.state.userData.id,
                };

                this.$http
                  .post("/opportunity/update", this.tempOpportunity)
                  .then(({ data }) => {
                    this.$message.success("Contrato criado com sucesso!");
                    this.loadingSubmitOpportunity = false;
                    data;
                  })
                  .catch(({ response }) => {
                    this.$message.error(response.data.message);
                    this.loadingSubmitOpportunity = false;
                  });
              })
              .catch(({ response }) => {
                this.$message.error(response.data.message);
                this.loadingSubmitOpportunity = false;
              });
          } else {
            this.$message.warning("Verifique os dados da oportunidade.");
          }
        });
      } else {
        this.$message.warning(
          "Você precisa preencher empresa e filial para criar o contrato."
        );
        this.activeOpportunityTab = "Dados da oportunidade";
      }
    },
    reloadHistory() {
      this.activeOpportunityTab = "";
      setTimeout(() => {
        this.activeOpportunityTab = "Histórico";
      }, 500);
    },
    updateFunnelSteps() {
      this.$refs.OptFunnelSteps.$emit("updateFunnelSteps2");
    },
    updateTempOpportunity(props, fields) {
      let field = fields ? Object.keys(fields)[0] : Object.keys(props)[0],
        value = fields ? Object.values(fields)[0] : Object.values(props)[0];

      this.tempOpportunity[field] = value;

      if (Array.isArray(value)) {
        this.tempOpportunity[field] = JSON.stringify(value);
      }
      this.tempOpportunity = { ...this.tempOpportunity };
    },
    emitUpdateTempOpportunity(tempOpportunity) {
      this.tempOpportunity = { ...this.tempOpportunity, ...tempOpportunity };
    },
    activeTab(tab) {
      this.activeOpportunityTab = tab;
    },
    openWhatsapp(opportunity) {
      let theNumber = opportunity.theCustomer.mobile_phone
        .replace(" ", "")
        .replace(" ", "")
        .replace("-", "")
        .replace("(", "")
        .replace(")", "");

      const message = `=Olá ${opportunity.theCustomer.first_name.trim()}, me chamo  ${
        opportunity.user ? opportunity.user.first_name.trim() : ""
      }! Recebemos sua cotação para ${
        opportunity.title
      } e estou aqui para ajudar. Vamos iniciar uma conversa?`;

      const baseUrl = `https://api.whatsapp.com/send`;

      window.open(`${baseUrl}?phone=${theNumber}&text=${message}`, "_blank");

      this.$http.post("/log/create", {
        user_id: this.$store.state.userData.id,
        module_id: this.tempOpportunity.id,
        module: "opportunity",
        action: "open-whatsapp",
        description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} clicou no botão <b>Whatsapp</b> para contato com o cliente.`,
      });
    },

    updateContractOptId() {
      this.$http.post("/contract-v2/update-field", {
        id: this.tempOpportunity.contract_id,
        field: "opportunity_id",
        value: this.tempOpportunity.id,
      });
    },
    async getContractSales() {
      try {
        const response = await this.$http.get(
          `/sale/list?page=1&per_page=10&contract-id=${this.tempOpportunity.contract_id}&order=desc&order-by=sale_date`
        );
        this.sales = response.data;
        if (this.sales.data.length == 1)
          this.updateSaleOptId(this.sales.data[0].id);
      } catch (error) {
        console.log(error);
      }
    },
    updateSaleOptId(saleId) {
      this.$http.post("/sale/update-field", {
        id: saleId,
        field: "opportunity_id",
        module: "opportunity",
        value: this.tempOpportunity.id,
        action: "update-opportunity-id",
        description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} atualizou o ID da oportunidade na venda ID ${saleId}.`,
        modified_by: {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        },
      });
    },
    submitOpportunity() {
      this.opportunityForm.validateFields((err) => {
        if (!err) {
          this.loadingSubmitOpportunity = true;

          this.tempOpportunity.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.tempOpportunity.value = this.tempOpportunity.value.replace(
            ",",
            "."
          );

          this.updateCustomer();

          if (
            this.tempOpportunity.contract_id != undefined &&
            this.tempOpportunity.contract_id != 0 &&
            this.tempOpportunity.contract_id != null
          ) {
            this.updateContractOptId();
            this.getContractSales();
          }

          this.tempOpportunity.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.$http
            .post("/opportunity/update", this.tempOpportunity)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.loadingSubmitOpportunity = false;
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingSubmitOpportunity = false;
            });
        } else {
          this.$message.warning("Verifique os dados da oportunidade.");
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.tab-icons
  float: left
  border-right: 1px solid #e0e0e0
  width: 75px
  height: 74vh
  text-align: center
  padding-right: 14px
.tab-content
  margin-left: 74px
  border-left: 1px solid #e0e0e0
  padding-left: 15px


.tab-icons .tab
  float: left
  height: 60px
  width: 100%
  line-height: 56px
  margin-bottom: 8px
  cursor: pointer
  border-radius: 6px
  transition: 0.6s
  opacity: 0.3

.tab-icons .tab:hover,
.tab-icons .tab.active
  background: #fafafa
  filter: none
  -webkit-filter: grayscale(0)
  -webkit-filter: grayscale(0%)
  opacity: 1

.json-viewer
  max-width: 840px
  background: #f5f5f5
  white-space: nowrap
  color: #333
  font-size: 14px
  border-radius: 8px
  font-family: Consolas, Menlo, Courier, monospace

  .jv-ellipsis
    color: #999
    background-color: #eee
    display: inline-block
    line-height: 0.9
    font-size: 0.9em
    padding: 0px 4px 2px 4px
    border-radius: 3px
    vertical-align: 2px
    cursor: pointer
    user-select: none

  .jv-button
    color: #49b3ff
  .jv-key
    color: #111111
  .jv-item
    &.jv-array
      color: #111111
    &.jv-boolean
      color: #fc1e70
    &.jv-function
      color: #067bca
    &.jv-number
      color: #fc1e70
    &.jv-number-float
      color: #fc1e70
    &.jv-number-integer
      color: #fc1e70
    &.jv-object
      color: #111111
    &.jv-undefined
      color: #e08331
    &.jv-string
      color: #42b983
      word-break: break-word
      white-space: normal

  .jv-code
    padding: 20px
    .jv-toggle
      &:before
        padding: 0px 2px
        border-radius: 2px

      &:hover
        &:before
          background: #eee

.title
  margin: 0 0 20px 0
  padding: 0 0 0px 0
  border-bottom: 1px solid #f0f0f0
  img
    margin-right: 5px
  h3
    font-size: 14px
    font-weight: 500
    color: #444

.sidebar
  float: left
  width: 360px
.content
  float: left
  width: calc(100% - 360px)
  .w-box
    min-height: 577px

.create-contract[disabled]
  pointer-events: none
  background: #f6f6f6
  opacity: 0.7
  border-color: #bbb !important
.create-contract
  background: #2ecc71
  border-color: #2ecc71
  font-weight: 600
  padding-top: 15px
  padding-bottom: 15px
  height: 59px
  font-size: 18px
  border-radius: 6px
.save
  padding-top: 10px
  padding-bottom: 15px
  height: 59px
  border-radius: 6px
  font-size: 20px
</style>
