<template>
  <div>
    <a-form @submit.prevent="submitCustomer" :form="customerForm">
      <CustomerFormV2
        :customer="tempOpportunity.theCustomer"
        :customerForm="customerForm"
        :disableCustomerMainData="false"
        :fieldSizes="fieldSizes"
        :allowEditCpf="true"
      />
    </a-form>
  </div>
</template>

<script>
import CustomerFormV2 from "@/components/customers/forms/CustomerFormV2.vue";

export default {
  name: "OptCustomerDataTab",
  props: {
    tempOpportunity: Object,
    opportunityForm: Object,
  },
  components: { CustomerFormV2 },
  data() {
    return {
      customerForm: this.$form.createForm(this, {
        onValuesChange: this.updateCustomer,
      }),

      fieldSizes: {
        companyName: 12,
        tradingName: 8,
        cnpj: 4,
        firstName: 8,
        lastName: 8,
        email: 8,
        birthday: 5,
        cpf: 6,
        rg: 5,
        rgEmissor: 4,
        rgState: 4,
        mobilePhone: 5,
        phone: 5,
        status: 4,
      },
    };
  },
  mounted() {},
  methods: {
    updateCustomer(props, fields) {
      let field = fields ? Object.keys(fields)[0] : Object.keys(props)[0],
        value = fields ? Object.values(fields)[0] : Object.values(props)[0];

      this.tempOpportunity.theCustomer[field] = value;

      if (Array.isArray(value)) {
        this.tempOpportunity.theCustomer[field] = JSON.stringify(value);
      }
      this.tempOpportunity.theCustomer = {
        ...this.tempOpportunity.theCustomer,
      };
    },
    submitCustomer() {
      this.customerForm.validateFields((err, customer) => {
        customer;
        if (!err) {
          // this.loadingSubmitCustomer = true;
          // customer.action = "create-customer";
          // customer.avatar = "";
          // customer.status = 1;
          // customer.user_id = this.$store.state.userData.id;
          // customer.modified_by = {
          //   name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          //   id: this.$store.state.userData.id,
          // };
          // customer = { ...customer, ...this.tempContract.the_customer };
          // this.$http
          //   .post("/customer/create", customer)
          //   .then(({ data }) => {
          //     this.$message.success(data.message);
          //     this.tempContract.customer_id = data.id;
          //     this.tempContract.the_customer = {
          //       ...this.tempContract.the_customer,
          //       ...customer,
          //     };
          //     setTimeout(() => {
          //       this.createContract();
          //     }, 50);
          //   })
          //   .catch(({ response }) => {
          //     response;
          //     this.$message.error(response.data.message);
          //     this.loadingSubmitCustomer = false;
          //   });
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped></style>
