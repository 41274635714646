<template>
  <div>
    <h1>
      <a-row type="flex" justify="space-between">
        <a-col>
          <img
            src="@/assets/images/dashboard/opportunities/pipeline.png"
            alt="img"
            width="20"
          />
          OPORTUNIDADE #{{ tempOpportunity.id }}
        </a-col>
      </a-row>
    </h1>

    <a-row
      v-if="tempOpportunity.created"
      class="meta"
      align="middle"
      type="flex"
      justify="start"
    >
      <a-col v-if="tempOpportunity.user.id">
        <a
          class="user dotted-phrase"
          @click="$router.push(`/users/view/${tempOpportunity.user.id}`)"
          v-if="tempOpportunity.user != undefined"
          target="_blank"
          style=""
        >
          <a-tooltip placement="right">
            <template slot="title">
              {{ tempOpportunity.user.first_name }}
              {{ tempOpportunity.user.last_name }}
            </template>
            <div v-if="tempOpportunity.user.id != 0">
              <a-avatar
                v-if="tempOpportunity.user.avatar"
                :src="tempOpportunity.user.avatar"
                :size="20"
              />
              <a-avatar
                v-else
                :size="20"
                class="upper"
                style="color: #f56a00; background-color: #fde3cf"
              >
                {{ tempOpportunity.user.first_name.substring(0, 1)
                }}{{ tempOpportunity.user.last_name.substring(0, 1) }}
              </a-avatar>
            </div>
            <div v-else>
              <a-avatar :size="20" icon="user" />
            </div>
          </a-tooltip>
        </a>
      </a-col>
      <a-col v-else> <a-avatar :size="20" icon="user" /> </a-col>

      <a-col>
        <a-divider type="vertical" />
        <a-icon class="cprimary" type="calendar" />
        {{
          tempOpportunity.created != undefined
            ? tempOpportunity.created
            : "" | formatMultiDates
        }}
      </a-col>
      <a-col>
        <a-divider type="vertical" />
        <a-icon class="cprimary" type="tag" /> {{ tempOpportunity.status }}
      </a-col>
      <a-col>
        <a-divider type="vertical" />
        <a-icon class="cprimary mr-5" type="global" />
        <font
          class="dotted-phrase"
          style="display: inline-block; width: 70px"
          >{{ tempOpportunity.origin }}</font
        >
      </a-col>
    </a-row>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "OptBasicInfos",
  props: {
    tempOpportunity: Object,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="sass" scoped>
.meta
  font-size: 10px
  line-height: 8px
  font-weight: 600
  text-transform: none
  padding-top: 10px
  border-top: 1px solid #eee
  margin-top: 4px
  .user
      color: #444
      max-width: 100px
      display: inline-block
h1
    margin: 0
    padding: 0
    font-weight: 600
    font-size: 22px
    letter-spacing: -1px
    line-height: 1.4
    img
        margin-right: 5px
</style>
