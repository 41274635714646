var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',{attrs:{"gutter":40}},[_vm._l((_vm.customFields),function(tab,index){return _c('a-col',{key:index,attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" "+_vm._s(tab.label)+" ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            tab.field_key,
            {
              initialValue: tab.value,
              rules: [
                {
                  required: tab.required,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            tab.field_key,\n            {\n              initialValue: tab.value,\n              rules: [\n                {\n                  required: tab.required,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input readonly",class:tab.field_key.includes('utm_') && !_vm.$root.isAdmin()
              ? 'readonly'
              : '',attrs:{"placeholder":""}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)}),_vm._v(" "),(_vm.customFields.length === 0)?_c('a-col',[_vm._v("\n      Nenhuma informação adicional para esta oportunidade.\n    ")]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }