<template>
  <div>
    <a-row class="customer">
      <a-col class="title" :span="24">
        <a-row type="flex" justify="space-between">
          <a-col>
            <img
              src="@/assets/images/dashboard/contracts/customer.png"
              width="19"
            />
            Cliente
          </a-col>
          <a-col>
            <a-icon
              class="c-pointer grayscale relative"
              type="edit-svg"
              style="zoom: 0.026; top: 95px"
              @click="activeTab('Dados do cliente')"
            />
          </a-col>
        </a-row>
      </a-col>
      <a-col class="infos" v-if="tempOpportunity.customer" :span="24">
        <div class="line">
          <span class="img">
            <a-icon type="user" />
          </span>
          <span
            class="upper dotted-phrase"
            v-if="tempOpportunity.customer.person_type == 'Pessoa Jurídica'"
          >
            {{ tempOpportunity.customer.id }} -
            {{ tempOpportunity.customer.trading_name }}
          </span>
          <span class="upper dotted-phrase" v-else>
            {{ tempOpportunity.customer.id }} -
            {{ tempOpportunity.customer.first_name }}
            {{ tempOpportunity.customer.last_name }}
          </span>
        </div>
        <div class="line">
          <span class="img"> <a-icon type="mobile" /> </span
          >{{ tempOpportunity.customer.mobile_phone }}
          {{
            tempOpportunity.customer.phone
              ? ` ou ${tempOpportunity.customer.phone}`
              : ""
          }}
        </div>
        <div class="line">
          <span class="img relative" style="top: -2px">
            <a-icon type="mail" />
          </span>
          <a-tooltip placement="bottom">
            <template slot="title">
              {{ tempOpportunity.customer.email }}
            </template>
            <div class="dotted-phrase">
              {{ tempOpportunity.customer.email }}
            </div>
          </a-tooltip>
        </div>
      </a-col>
      <a-col v-else class="infos" :span="24">
        <div class="line">
          <span class="img">
            <a-icon type="user" />
          </span>
          <span class="upper dotted-phrase">
            {{ tempOpportunity.customer_name }}
          </span>
        </div>
        <div class="line">
          <span class="img"> <a-icon type="mobile" /> </span
          >{{ tempOpportunity.mobile_phone }}
        </div>
        <div class="line">
          <span class="img relative" style="top: -2px">
            <a-icon type="mail" />
          </span>
          <a-tooltip placement="bottom">
            <template slot="title">
              {{ tempOpportunity.unique_id }}
            </template>
            <div class="dotted-phrase">
              {{ tempOpportunity.unique_id }}
            </div>
          </a-tooltip>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "OptCustomerBasicInfos",
  props: {
    tempOpportunity: Object,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    activeTab(tab) {
      this.$emit("activeTab", tab);
    },
  },
};
</script>

<style lang="sass" scoped>
.customer
  .infos
    font-weight: 600
    font-size: 13px
    .line
      padding: 7px 0
      line-height: 1
      .dotted-phrase
        max-width: 250px
        display: inline-block
    .img
      width: 20px
      display: inline-block
      text-align: center
      margin-right: 5px
      i
        color: #aaa
  .title
    border-bottom: 1px solid #eee
    padding: 0 0 10px
    margin: 0 0 10px
    i
      font-size: 16px
</style>
