<template>
  <div>
    <a-row :gutter="20">
      <a-col :span="8">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Título da Oportunidade </label>
          <a-input
            class="travel-input"
            placeholder="Escreva"
            v-decorator="[
              `title`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Funil </label>
          <a-select
            class="travel-input"
            placeholder="Selecione"
            optionFilterProp="txt"
            @change="onSelectFunnel"
            v-decorator="[
              `funnel_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) of opportunityFunnels.list"
              :key="index"
              :value="item.id"
              :txt="item.name"
            >
              {{ item.id }} - {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'">Origem da oportunidade </label>
          <a-select
            class="travel-input"
            placeholder="Selecione"
            optionFilterProp="txt"
            v-decorator="[
              `origin`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) of originsList"
              :key="index"
              :value="item.name"
              :txt="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col class="none" :span="8">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Nome do cliente </label>

          <a-input
            class="travel-input"
            placeholder="Escreva"
            v-decorator="[
              'customer_name',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col class="none" :span="8">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> E-mail </label>
          <a-input
            class="travel-input"
            placeholder="Escreva"
            v-decorator="['unique_id']"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col class="none" :span="8">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Celular </label>
          <a-input
            class="travel-input"
            placeholder="Escreva"
            v-mask="'(##) # ####-####'"
            v-decorator="['mobile_phone']"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'">Valor (opcional)</label>

          <a-input
            class="travel-input"
            placeholder="Insira o valor"
            v-currency
            v-decorator="[
              `value`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Preencha o valor.',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Empresa </label>
          <a-select
            class="travel-input"
            placeholder="Selecione uma empresa"
            optionFilterProp="txt"
            @change="getCompanyBranchesOnChange"
            v-decorator="[
              `company_id`,
              {
                rules: [
                  {
                    required:
                      tempOpportunity.funnel_step.allow_contract == 1
                        ? true
                        : false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            show-search
            style="width: 100%"
            :disabled="companiesList.length > 0 ? false : true"
          >
            <a-select-option
              v-for="(item, index) of companiesList"
              :key="index"
              :value="item.id"
              :txt="item.trading_name"
            >
              {{ item.id }} - {{ item.trading_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Filial </label>
          <a-select
            class="travel-input"
            placeholder="Selecione uma filial"
            optionFilterProp="txt"
            :disabled="companyBranchesList.length === 0"
            v-decorator="[
              `company_branch_id`,
              {
                rules: [
                  {
                    required:
                      tempOpportunity.funnel_step.allow_contract == 1
                        ? true
                        : false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            show-search
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) of companyBranchesList"
              :key="index"
              :value="item.id"
              :txt="item.name"
            >
              {{ item.id }} - {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col v-if="$root.isAdmin()" :span="8">
        <a-form-item class="travel-input">
          <label :class="'filled'">Data de fechamento (somente admin)</label>
          <a-date-picker
            placeholder="Selecione"
            format="DD/MM/YYYY"
            valueFormat="YYYY-MM-DD"
            :showToday="false"
            :allowClear="true"
            v-mask="'##/##/####'"
            v-decorator="['release_date']"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-date-picker>
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import userMixins from "@/mixins/user/userMixins.js";
import opportunityFunnelsMixins from "../mixins/opportunityFunnelsMixins";
import opportunitiesFunnelStepsMixins from "../mixins/opportunitiesFunnelStepsMixins";
import customerMixins from "@/components/customers/mixins/customerMixins";

export default {
  name: "OptDataTab",
  props: {
    funnelId: String,
    opportunityForm: Object,
    tempOpportunity: Object,
  },
  mixins: [
    userMixins,
    opportunityFunnelsMixins,
    opportunitiesFunnelStepsMixins,
    customerMixins,
  ],
  data() {
    return {
      originsList: [],
      companiesList: [],
      companyBranchesList: [],
    };
  },
  mounted() {
    this.user.filters.status.selected = "1";
    this.user.pagination.perPage = 500;
    this.getUsers();

    this.$http.get(`/company/list?page=1&per_page=100`).then(({ data }) => {
      this.companiesList = data.data;
    });

    if (this.tempOpportunity["company_id"] != undefined) {
      this.getCompanyBranches(this.tempOpportunity["company_id"]);
    }

    this.opportunityFunnels.filters.orderBy = "funnel_order";
    this.opportunityFunnels.filters.order = "ascend";
    this.getOpportunityFunnels();

    setTimeout(() => {
      this.opportunityForm.setFieldsValue(this.tempOpportunity);
      setTimeout(() => {
        this.opportunityForm.setFieldsValue({
          value: this.tempOpportunity.value.replace(".", ","),
          release_date:
            this.tempOpportunity.release_date != "0000-00-00"
              ? this.tempOpportunity.release_date
              : undefined,
        });
      }, 300);
    }, 100);

    this.$http
      .get(`/category-entry/list?page=1&category_id=26&per_page=300`)
      .then(({ data }) => {
        this.originsList = data.data;
      });
  },
  methods: {
    onSelectFunnel(id) {
      this.opportunitiesFunnelSteps.filters.funnelId = id;
      this.opportunitiesFunnelSteps.filters.status.selected = ["Ativado"];
      this.getOpportunitiesFunnelSteps();
      this.tempOpportunity.step = "";
      this.$emit("updateFunnelSteps", id);
    },
    searchCustomers() {
      setTimeout(() => {
        this.getCustomers();
      }, 500);
    },
    getCompanyBranchesOnChange(id) {
      this.opportunityForm.setFieldsValue({
        [`company_branch_id`]: undefined,
        company_branch: undefined,
      });

      let company = this.companiesList.filter((c) => {
        return c.id == id;
      });

      this.opportunityForm.setFieldsValue({
        [`company`]: company[0].trading_name,
      });

      this.companyBranchesList = [];
      this.getCompanyBranches(id);
    },
    getCompanyBranches(companyId) {
      this.$http
        .get(
          `/company-branch/list?page=1&per_page=100&status=Ativo&show_in_contract=1&company_id=${companyId}`
        )
        .then(({ data }) => {
          this.companyBranchesList = data.data;
        });
    },
    onChangeUser(id, user) {
      this.tempOpportunity.user = user.data.attrs.user;
    },
    onCustomerSelected(customerId) {
      this.opportunityForm.setFieldsValue({
        [`customer_id`]: customerId,
      });

      this.getCustomer(customerId);
      setTimeout(() => {
        this.opportunityForm.setFieldsValue({
          mobile_phone: this.customers.details.mobile_phone,
          unique_id: this.customers.details.email,
        });
      }, 200);
    },
  },
};
</script>

<style lang="sass" scoped>
.step-name
  margin-bottom: 5px
  font-size: 12px
  color: #333
.steps
  display: flex
  align-items: center
  justify-content: center
  align-content: center
  .step.active
    background: #be4178
  .step.active:before
    background: #be4178
  .step.hover
    background: #aaa
  .step.hover:before
    background: #aaa
  .step:before
    width: 30px
    height: 30px
    position: absolute
    right: -14px
    top: 2px
    border-top: 6px solid #FFF
    border-right: 6px solid #FFF
    -webkit-transform: scaleX(0.3) rotate(45deg)
    -moz-transform: scaleX(0.3) rotate(45deg)
    -ms-transform: scaleX(0.3) rotate(45deg)
    -o-transform: scaleX(0.3) rotate(45deg)
    transform: scaleX(0.3) rotate(45deg)
    content: " "
    background: #ccc
    cursor: pointer
    z-index: 1
  .step
    position: relative
    flex: 1
    height: 34px
    cursor: pointer
    text-align: center
    padding: 2px 6px
    font-size: 8px
    color: #FFF
    background: #ccc
    vertical-align: middle
    border-radius: 4px
</style>
