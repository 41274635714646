var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Título da Oportunidade ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `title`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `title`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Funil ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `funnel_id`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `funnel_id`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt"},on:{"change":_vm.onSelectFunnel}},_vm._l((_vm.opportunityFunnels.list),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.name}},[_vm._v("\n            "+_vm._s(item.id)+" - "+_vm._s(item.name)+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Origem da oportunidade ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `origin`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `origin`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt"}},_vm._l((_vm.originsList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.name,"txt":item.name}},[_vm._v("\n            "+_vm._s(item.name)+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{staticClass:"none",attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Nome do cliente ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'customer_name',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'customer_name',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{staticClass:"none",attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" E-mail ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['unique_id']),expression:"['unique_id']"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{staticClass:"none",attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Celular ")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('(##) # ####-####'),expression:"'(##) # ####-####'"},{name:"decorator",rawName:"v-decorator",value:(['mobile_phone']),expression:"['mobile_phone']"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Valor (opcional)")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            `value`,
            {
              rules: [
                {
                  required: false,
                  message: 'Preencha o valor.',
                },
              ],
            },
          ]),expression:"[\n            `value`,\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Preencha o valor.',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira o valor"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Empresa ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `company_id`,
            {
              rules: [
                {
                  required:
                    _vm.tempOpportunity.funnel_step.allow_contract == 1
                      ? true
                      : false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `company_id`,\n            {\n              rules: [\n                {\n                  required:\n                    tempOpportunity.funnel_step.allow_contract == 1\n                      ? true\n                      : false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione uma empresa","optionFilterProp":"txt","show-search":"","disabled":_vm.companiesList.length > 0 ? false : true},on:{"change":_vm.getCompanyBranchesOnChange}},_vm._l((_vm.companiesList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.trading_name}},[_vm._v("\n            "+_vm._s(item.id)+" - "+_vm._s(item.trading_name)+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Filial ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `company_branch_id`,
            {
              rules: [
                {
                  required:
                    _vm.tempOpportunity.funnel_step.allow_contract == 1
                      ? true
                      : false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `company_branch_id`,\n            {\n              rules: [\n                {\n                  required:\n                    tempOpportunity.funnel_step.allow_contract == 1\n                      ? true\n                      : false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione uma filial","optionFilterProp":"txt","disabled":_vm.companyBranchesList.length === 0,"show-search":""}},_vm._l((_vm.companyBranchesList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.name}},[_vm._v("\n            "+_vm._s(item.id)+" - "+_vm._s(item.name)+"\n          ")])}),1)],1)],1),_vm._v(" "),(_vm.$root.isAdmin())?_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{class:'filled'},[_vm._v("Data de fechamento (somente admin)")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:(['release_date']),expression:"['release_date']"}],attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY","valueFormat":"YYYY-MM-DD","showToday":false,"allowClear":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }