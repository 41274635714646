<template>
  <div>
    <a-row :gutter="40">
      <a-col v-for="(tab, index) in customFields" :key="index" :span="8">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> {{ tab.label }} </label>
          <a-input
            class="travel-input readonly"
            :class="
              tab.field_key.includes('utm_') && !$root.isAdmin()
                ? 'readonly'
                : ''
            "
            placeholder=""
            v-decorator="[
              tab.field_key,
              {
                initialValue: tab.value,
                rules: [
                  {
                    required: tab.required,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>
      <a-col v-if="customFields.length === 0">
        Nenhuma informação adicional para esta oportunidade.
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "OptCustomFieldsTab",
  props: {
    tempOpportunity: Object,
    opportunityForm: Object,
  },
  data() {
    return {
      customFields: [],
    };
  },
  mounted() {
    let allFieldsSize = this.tempOpportunity.meta
        ? Object.values(this.tempOpportunity.meta).length
        : 0,
      values = this.tempOpportunity.meta
        ? Object.values(this.tempOpportunity.meta)
        : [],
      keys = this.tempOpportunity.meta
        ? Object.keys(this.tempOpportunity.meta)
        : [];

    //   console.log("allFieldsSize", allFieldsSize, values, keys);

    for (let i = 0; i < allFieldsSize; i++) {
      if (
        keys[i] !== "allow_contract" &&
        keys[i] !== "allow_create_lead" &&
        keys[i] !== "release_date" &&
        keys[i] !== "situation" &&
        keys[i] !== "reason_to_freeze_opportunity" &&
        keys[i] !== "reason_observation_to_freeze_opportunity" &&
        keys[i] !== "freeze_opportunity_until_date" &&
        keys[i] !== "reason_to_lose_opportunity" &&
        keys[i] !== "reason_observation_to_lose_opportunity" &&
        values[i] !== "-- " &&
        !keys[i].includes("opportunity_files_")
      )
        this.customFields.push({
          field_key: keys[i],
          label: keys[i]
            .replace("_", " ")
            .replace("_", " ")
            .replace("_", " ")
            .replace("_", " "),
          value: values[i],
          type: "text",
          required: false,
        });
    }

    // console.log("allFieldsSize", this.customFields);
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
label
  text-transform: capitalize
</style>
