export default {
  data() {
    return {
      opportunity: {
        loading: false,
        details: {},
        list: [],
        listLoadMore: [],
        meta: {},
        filters: {
          id: "",
          title: "",
          haveContractAndSale: [],
          users: {
            list: [],
            selected: [],
          },
          funnelId: "",
          companies: {
            list: [],
            selected: [],
          },
          companyBranches: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Aberto",
                value: "Aberto",
              },
              {
                label: "Ganho",
                value: "Ganho",
              },
              {
                label: "Perdido",
                value: "Perdido",
              },
            ],
            selected: [],
          },
          situation: {
            list: [
              {
                label: "Congelado",
                value: "Congelado",
              },
              {
                label: "Lixeira",
                value: "Lixeira",
              },
            ],
            selected: [],
          },
          utm: {
            source: "",
            medium: "",
            campaign: "",
            term: "",
            content: "",
          },
          customers: {
            list: [],
            selected: [],
            type: [],
          },
          origin: {
            list: [],
            selected: [],
          },
          step: {
            list: [],
            selected: [],
          },
          releaseDate: {
            selected: [],
          },
          period: {
            selected: [],
          },
          price: {
            min: "",
            max: "",
          },
          order: "desc",
          orderBy: "created",
        },
        pagination: {
          page: 1,
          perPage: 30,
          total: 0,
          complete_list_total: 0,
          totalPages: 0,
        },
      },
      tempOpportunity: {},
      opportunityJson: {},
      blocks: [],
    };
  },
  methods: {
    changeOpportunityPage(current) {
      this.opportunity.pagination.page = current;
      this.getOpportunities();
    },
    changeOpportunityPageSize(current, pageSize) {
      this.opportunity.pagination.page = current;
      this.opportunity.pagination.perPage = pageSize;
      this.getOpportunities();
    },
    opportunityTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      this.opportunity.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.opportunity.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getOpportunities();
    },
    getOpportunity(id) {
      // load
      this.$http
        .post(`/opportunity/details?id=${id}`)
        .then(({ data }) => {
          if (!this.$root.isAdmin() && !this.$root.isAnalyst()) {
            if (data.user.id != this.$store.state.userData.id) {
              this.$tabs.close(`/opportunity/edit/${data.id}`, `/pipeline`);
            }
          }

          this.opportunity.details = data;
          this.tempOpportunity = data;
          let opportunityJson = data.raw;

          // OPT TEMPORARIA
          this.tempOpportunity = {
            ...data,
            funnel_id: data.funnel.id,
            user_id: data.user.id ? data.user.id : "0",
            customer_id: data.customer.id,
            contract_id: data.raw.contract_id,
            sale_id: data.raw.sale_id,
            company_id: data.company.id,
            company_branch_id: data.company_branch.id,
            theCustomer: { ...data.customer, ...data.customer.meta },
          };

          let allFieldsSize = Object.values(data.meta).length,
            values = Object.values(data.meta),
            keys = Object.keys(data.meta);

          for (let i = 0; i < allFieldsSize; i++) {
            this.tempOpportunity[keys[i]] = values[i];
          }

          //delete this.tempOpportunity.meta;
          delete this.tempOpportunity.raw;
          delete this.tempOpportunity.theCustomer.meta;

          opportunityJson.custom_fields = data.meta;
          delete opportunityJson.custom_fields.opportunity_json;

          this.opportunityJson = opportunityJson;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    opportunityFilters() {
      let filters = "",
        queryParams = new URLSearchParams("");
      history.pushState(null, null, "");

      if (!this.$root.isAdmin() && !this.$root.isAnalyst()) {
        filters += `&user_id=${this.$store.state.userData.id}`;
      } else {
        if (this.opportunity.filters.users.selected.length > 0) {
          filters += `&user_id=${this.opportunity.filters.users.selected}`;
          queryParams.set("user", `${this.opportunity.filters.users.selected}`);
        }
      }

      if (this.opportunity.filters.customers.selected.length > 0) {
        filters += `&customer_id=${this.opportunity.filters.customers.selected}`;
        queryParams.set(
          "customer_id",
          `${this.opportunity.filters.customers.selected}`
        );
      }

      if (this.opportunity.filters.customers.type.length > 0) {
        filters += `&customer_type=${this.opportunity.filters.customers.type}`;
      }

      if (this.opportunity.filters.haveContractAndSale.length > 0) {
        filters += `&have_contract_sale=${this.opportunity.filters.haveContractAndSale}`;
      }

      if (this.opportunity.filters.title) {
        filters += `&title=${this.opportunity.filters.title}`;
        queryParams.set("title", `${this.opportunity.filters.title}`);
      }

      if (this.opportunity.filters.utm.source) {
        filters += `&utm_source=${this.opportunity.filters.utm.source}`;
        queryParams.set("utm_source", `${this.opportunity.filters.utm.source}`);
      }

      if (this.opportunity.filters.utm.medium) {
        filters += `&utm_medium=${this.opportunity.filters.utm.medium}`;
        queryParams.set("utm_medium", `${this.opportunity.filters.utm.medium}`);
      }

      if (this.opportunity.filters.utm.campaign) {
        filters += `&utm_campaign=${this.opportunity.filters.utm.campaign}`;
        queryParams.set(
          "utm_campaign",
          `${this.opportunity.filters.utm.campaign}`
        );
      }

      if (this.opportunity.filters.utm.term) {
        filters += `&utm_term=${this.opportunity.filters.utm.term}`;
        queryParams.set("utm_term", `${this.opportunity.filters.utm.term}`);
      }

      if (this.opportunity.filters.utm.content) {
        filters += `&utm_content=${this.opportunity.filters.utm.content}`;
        queryParams.set(
          "utm_content",
          `${this.opportunity.filters.utm.content}`
        );
      }

      if (this.opportunity.filters.funnelId) {
        filters += `&funnel-id=${this.opportunity.filters.funnelId}`;
        queryParams.set("funnel-id", `${this.opportunity.filters.funnelId}`);
      }

      if (this.opportunity.filters.companies.selected.length > 0) {
        filters += `&company_id=${this.opportunity.filters.companies.selected}`;
      }

      if (this.opportunity.filters.companyBranches.selected.length > 0) {
        filters += `&company_branch_id=${this.opportunity.filters.companyBranches.selected}`;
      }

      if (this.opportunity.filters.status.selected.length > 0) {
        filters += `&status=${this.opportunity.filters.status.selected}`;
        queryParams.set(
          "status",
          `${this.opportunity.filters.status.selected}`
        );
      }

      if (this.opportunity.filters.situation.selected.length > 0) {
        filters += `&situation=${this.opportunity.filters.situation.selected}`;
        queryParams.set(
          "situation",
          `${this.opportunity.filters.situation.selected}`
        );
      } else {
        filters += `&situation=Nenhuma`;
        queryParams.set("situation", `Nenhuma`);
      }

      if (this.opportunity.filters.origin.selected.length > 0) {
        filters += `&origin=${this.opportunity.filters.origin.selected}`;
        queryParams.set(
          "origin",
          `${this.opportunity.filters.origin.selected}`
        );
      }

      if (this.opportunity.filters.step.selected.length > 0) {
        filters += `&step=${this.opportunity.filters.step.selected}`;
      }

      if (this.opportunity.filters.period.selected.length > 0) {
        filters += `&period=${this.opportunity.filters.period.selected[0]}|${this.opportunity.filters.period.selected[1]}`;
        queryParams.set(
          "period",
          `${this.opportunity.filters.period.selected[0]}|${this.opportunity.filters.period.selected[1]}`
        );
      }

      if (this.opportunity.filters.releaseDate.selected.length > 0) {
        filters += `&release-date=${this.opportunity.filters.releaseDate.selected[0]}|${this.opportunity.filters.releaseDate.selected[1]}`;
        queryParams.set(
          "release-date",
          `${this.opportunity.filters.releaseDate.selected[0]}|${this.opportunity.filters.releaseDate.selected[1]}`
        );
      }

      if (
        this.opportunity.filters.price.min ||
        this.opportunity.filters.price.max
      ) {
        let min = this.opportunity.filters.price.min;
        let max = this.opportunity.filters.price.max;

        if (min) {
          if (!max) {
            max = "500000";
          }
        }

        if (max) {
          if (!min) {
            min = "0";
          }
        }

        filters += `&price-range=${min}-${max}`;
      }

      if (this.opportunity.filters.id) {
        filters += `&id=${this.opportunity.filters.id}`;
      }

      filters += `&order=${this.opportunity.filters.order}&order-by=${this.opportunity.filters.orderBy}`;

      history.replaceState(null, null, "?" + queryParams.toString());

      return filters;
    },
    getOpportunities(samePage, isLoadMore) {
      this.opportunity.loading = true;

      this.$http
        .get(
          `/opportunity/list?page=${
            this.opportunity.pagination.page
          }&per_page=${
            this.opportunity.pagination.perPage
          }${this.opportunityFilters()}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.opportunity.pagination.page = 1;
          }

          this.opportunity.loading = false;
          this.opportunity.pagination.total = data.meta.total;
          this.opportunity.pagination.totalPages = data.meta.total_pages;

          if (isLoadMore) {
            this.opportunity.pagination.page = parseInt(data.meta.next_page);
            this.opportunity.listLoadMore = [
              ...this.opportunity.listLoadMore,
              ...data.data,
            ];
          } else {
            this.opportunity.pagination.page = parseInt(data.meta.page);
            this.opportunity.list = data.data;
          }

          this.opportunity.meta = data.meta;

          this.$emit("opportunities-loaded");
        })
        .catch(({ response }) => {
          this.opportunity.loading = false;
          this.opportunity.pagination.total = response.data.meta.total;
          this.opportunity.meta = response.data.meta;

          if (isLoadMore) {
            this.opportunity.pagination.page = 0;
            this.opportunity.listLoadMore = [];
          } else {
            this.opportunity.list = [];
            this.opportunity.pagination.page = response.meta.page;
          }

          this.opportunity.pagination.page = response.meta.next_page;
          this.opportunity.pagination.totalPages = response.meta.total_pages;

          this.$emit("opportunities-loaded");
        });
    },
  },
};
