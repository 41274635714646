<template>
  <aTabs type="card" :activeKey="activeTab" @change="onChangeTabs">
    <aTabPane v-for="tab in customerTabs" :key="tab.title">
      <div slot="tab">{{ tab.title }}</div>
      <Component
        v-if="activeTab === tab.title && customer.id"
        :is="tab.component"
        :customerForm="customerForm"
        :customer="customer"
        :newFieldSizes="
          tab.component === 'CustomerFormDefaultData'
            ? newFieldSizes
            : undefined
        "
        :disableCustomerMainDatas="
          tab.component === 'CustomerFormDefaultData' ? false : false
        "
        :newAddressFieldSizes="
          tab.component === 'CustomerFormAddress'
            ? newAddressFieldSizes
            : undefined
        "
        :allowEditCpf="
          tab.component === 'CustomerFormDefaultData' ? allowEditCpf : false
        "
        :requiredFields="undefined"
      ></Component>
    </aTabPane>
  </aTabs>
</template>

<script>
import CustomerFormDefaultData from "../tabs/CustomerFormDefaultData.vue";
import CustomerFormExtraContacts from "../tabs/CustomerFormExtraContacts.vue";
import CustomerFormAddress from "../tabs/CustomerFormAddress.vue";

export default {
  name: "CustomerFormV2",
  props: {
    customerForm: Object,
    fieldSizes: Object,
    addressFieldSizes: Object,
    customer: Object,
    disableCustomerMainData: Boolean,
    allowEditCpf: Boolean,
  },
  components: {
    CustomerFormDefaultData,
    CustomerFormExtraContacts,
    CustomerFormAddress,
  },
  data() {
    return {
      activeTab: "Dados",
      customerTabs: [
        {
          title: "Dados",
          component: "CustomerFormDefaultData",
        },
        {
          title: "Endereço",
          component: "CustomerFormAddress",
        },
        {
          title: "Contatos",
          component: "CustomerFormExtraContacts",
        },
      ],
      newAddressFieldSizes: {
        cep: 4,
        location_street: 16,
        location_number: 4,
        location_complement: 8,
        location_neighborhood: 8,
        location_city: 8,
        location_state: 3,
        location_country: 5,
      },
      newFieldSizes: {
        companyName: 12,
        tradingName: 8,
        cnpj: 4,
        firstName: 8,
        lastName: 8,
        email: 8,
        birthday: 5,
        cpf: 6,
        rg: 5,
        rgEmissor: 4,
        rgState: 4,
        mobilePhone: 5,
        phone: 5,
        status: 4,
      },
    };
  },
  beforeCreate() {
    setTimeout(() => {
      if (this.fieldSizes != undefined) this.newFieldSizes = this.fieldSizes;
      if (this.addressFieldSizes != undefined)
        this.newFieldSizes = this.addressFieldSizes;
    }, 1000);
  },
  methods: {
    onChangeTabs(key) {
      this.activeTab = key;
    },
  },
};
</script>
