<template>
  <aRow v-if="newAddressFieldSizes != undefined" :gutter="20">
    <aCol :span="newAddressFieldSizes.cep">
      <a-form-item class="travel-input-outer">
        <label :class="'filled'"> CEP </label>
        <a-input
          class="travel-input"
          placeholder="Insira"
          :disabled="disabledCondition"
          v-mask="'#####-###'"
          @change="getLocation()"
          v-decorator="[
            'cep',
            {
              rules: [
                {
                  required:
                    isRequired() === false
                      ? false
                      : requiredFields != undefined
                      ? requiredFields.cep
                      : true,
                  min: 8,
                  message: 'Obrigatório.',
                },
              ],
            },
          ]"
        >
          <a-icon v-if="!disabledCondition" slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol :span="newAddressFieldSizes.location_street">
      <a-form-item class="travel-input-outer">
        <label :class="'filled'"> Logradouro </label>
        <a-input
          class="travel-input"
          :disabled="disabledCondition"
          placeholder="Ex: Rua..."
          v-decorator="[
            'location_street',
            {
              rules: [
                {
                  required:
                    isRequired() === false
                      ? false
                      : requiredFields != undefined
                      ? requiredFields.location_street
                      : true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon v-if="!disabledCondition" slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol :span="newAddressFieldSizes.location_number">
      <a-form-item class="travel-input-outer">
        <label :class="'filled'"> Número </label>
        <a-input
          type="number"
          class="travel-input"
          :disabled="disabledCondition"
          placeholder="Insira"
          v-decorator="[
            'location_number',
            {
              rules: [
                {
                  required:
                    isRequired() === false
                      ? false
                      : requiredFields != undefined
                      ? requiredFields.location_number
                      : true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon v-if="!disabledCondition" slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol :span="newAddressFieldSizes.location_complement">
      <a-form-item class="travel-input-outer">
        <label :class="'filled'"> Complemento </label>
        <a-input
          class="travel-input"
          placeholder="Insira"
          :disabled="disabledCondition"
          v-decorator="['location_complement']"
        >
          <a-icon v-if="!disabledCondition" slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol :span="newAddressFieldSizes.location_neighborhood">
      <a-form-item class="travel-input-outer">
        <label :class="'filled'"> Bairro </label>
        <a-input
          class="travel-input"
          placeholder="Insira"
          :disabled="disabledCondition"
          v-decorator="[
            'location_neighborhood',
            {
              rules: [
                {
                  required:
                    isRequired() === false
                      ? false
                      : requiredFields != undefined
                      ? requiredFields.location_neighborhood
                      : true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon v-if="!disabledCondition" slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol :span="newAddressFieldSizes.location_city">
      <a-form-item class="travel-input-outer">
        <label :class="'filled'"> Cidade </label>
        <a-input
          class="travel-input"
          placeholder="Insira"
          :disabled="disabledCondition"
          v-decorator="[
            'location_city',
            {
              rules: [
                {
                  required:
                    isRequired() === false
                      ? false
                      : requiredFields != undefined
                      ? requiredFields.location_city
                      : true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon v-if="!disabledCondition" slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol :span="newAddressFieldSizes.location_state">
      <a-form-item class="travel-input-outer">
        <label :class="'filled'"> Estado </label>
        <a-select
          class="travel-input"
          show-search
          :disabled="disabledCondition"
          placeholder="Selecione"
          allowClear
          :style="'width: 100%'"
          v-decorator="[
            'location_state',
            {
              rules: [
                {
                  required:
                    isRequired() === false
                      ? false
                      : requiredFields != undefined
                      ? requiredFields.location_state
                      : true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-select-option
            v-for="(item, index) of states"
            :key="index"
            :value="item.initials"
          >
            {{ item.initials.toUpperCase() }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </aCol>

    <aCol :span="newAddressFieldSizes.location_country">
      <a-form-item class="travel-input-outer">
        <label :class="'filled'"> País </label>
        <a-select
          class="travel-input"
          show-search
          :disabled="disabledCondition"
          placeholder="Selecione"
          optionFilterProp="txt"
          :style="'width: 100%'"
          allowClear
          v-decorator="[
            'location_country',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-select-option
            v-for="(item, index) of countries"
            :key="index"
            :value="item.Pais.toUpperCase()"
            :txt="item.Pais"
          >
            {{ item.Pais.toUpperCase() }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </aCol>
  </aRow>
</template>

<script>
import states from "@/json/states";
import countries from "@/json/countries";
import axios from "axios";

export default {
  name: "CustomerFormAddress",
  props: {
    customerForm: Object,
    customer: Object,
    disabledCondition: Boolean,
    requiredFields: Boolean,
    newAddressFieldSizes: Object,
  },
  data() {
    return {
      states,
      countries,
    };
  },
  mounted() {
    setTimeout(() => {
      this.customerForm.setFieldsValue(this.customer);
      this.customerForm.setFieldsValue({
        location_country: this.customer.location_country
          ? this.customer.location_country
          : undefined,
        location_state: this.customer.location_state
          ? this.customer.location_state
          : undefined,
      });
    }, 100);
  },
  methods: {
    getLocation() {
      setTimeout(() => {
        if (this.customerForm.getFieldValue("cep")) {
          if (
            this.customerForm.getFieldValue("cep").replace("-", "").length == 8
          ) {
            this.cepLoading = true;
            axios
              .get(
                "https://viacep.com.br/ws/" +
                  this.customerForm.getFieldValue("cep") +
                  "/json/"
              )
              .then(({ data }) => {
                data;
                this.cepLoading = false;
                let country = undefined;

                this.states.forEach((state) => {
                  state.initials == data.uf ? (country = "BRASIL") : "";
                });

                setTimeout(() => {
                  this.customerForm.setFieldsValue({
                    location_street: data.logradouro.toUpperCase(),
                    location_state: data.uf,
                    location_number: data.complemento,
                    location_city: data.localidade.toUpperCase(),
                    location_neighborhood: data.bairro.toUpperCase(),
                    location_country: country,
                  });
                }, 20);
              })
              .catch(({ response }) => {
                response;
                this.$message.error(
                  "Houve um problema ao recuperar a localização."
                );
                this.cepLoading = false;
              });
          }
        }
      }, 110);
    },
    isRequired() {
      let flag = true;
      const pageUrl = window.location.pathname.includes("/contracts/new");

      if (pageUrl === true || this.customer.is_international) flag = false;

      return flag;
    },
  },
};
</script>
