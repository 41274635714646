var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"w-box"},[_c('a-form-item',{staticClass:"travel-input-outer mt-15"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v(" ID do contrato ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `contract_id`,
          {
            rules: [
              {
                required: false,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `contract_id`,\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",class:_vm.$root.isAdmin() ? '' : 'readonly',attrs:{"type":"number","placeholder":"Insira"},scopedSlots:_vm._u([{key:"addonAfter",fn:function(){return [(_vm.loadingContracts)?_c('a-icon',{attrs:{"type":"loading"}}):_vm._e()]},proxy:true}])})],1),_vm._v(" "),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.tempOpportunity.contract_id != 0),expression:"tempOpportunity.contract_id != 0"}],staticClass:"travel-input-outer mt-15"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v(" ID da venda ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `sale_id`,
          {
            rules: [
              {
                required: false,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `sale_id`,\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",class:_vm.$root.isAdmin() ? '' : 'readonly',attrs:{"type":"number","placeholder":"Insira"},scopedSlots:_vm._u([{key:"addonAfter",fn:function(){return [(_vm.loadingSales)?_c('a-icon',{attrs:{"type":"loading"}}):_vm._e()]},proxy:true}])})],1),_vm._v(" "),_c('a-form-item',{staticClass:"travel-input-outer mt-15"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Responsável pela oportunidade ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `user_id`,
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `user_id`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",class:_vm.$root.isAdmin() ? '' : 'readonly',staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt","show-search":""},on:{"change":_vm.onChangeUser}},_vm._l((_vm.listUsers(_vm.user.list)),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.first_name,"user":item}},[(item.id == 0)?_c('div',[_vm._v(_vm._s(item.first_name))]):_c('div',[_vm._v("\n            "+_vm._s(item.id)+" - "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+"\n          ")])])}),1)],1)],1),_vm._v(" "),_c('div',{staticClass:"submenu"},[_c('a-row',_vm._l((_vm.submenu),function(item,index){return _c('a-col',{key:index,attrs:{"span":24}},[(item.showItem)?_c('div',{staticClass:"row border c-pointer hover",class:`${item.extraClass} ${
            item.disabled ? 'disabled grayscale' : ''
          }`,on:{"click":function($event){return _vm.activeTab(item.name)}}},[_c('span',{staticClass:"txt"},[_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_c('span',{staticClass:"img"},[_c('img',{attrs:{"src":item.img,"alt":item.name,"width":"15"}})]),_vm._v("\n                "+_vm._s(item.name)+"\n              ")]),_vm._v(" "),_c('a-col')],1)],1)]):_vm._e()])}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }