<template>
  <div>
    <!-- <FilesTable
      s3Folder="opportunities"
      :moduleName="`opportunity`"
      :moduleId="tempOpportunity.id"
      :customerId="tempOpportunity.customer_id"
      :searchModules="`opportunity${
        tempOpportunity.contract_id != 0 ? ',contract' : ''
      }${tempOpportunity.sale_id != 0 ? ',sale' : ''}`"
      :searchModuleIds="`${tempOpportunity.id}${
        tempOpportunity.contract_id != 0
          ? ',' + tempOpportunity.contract_id
          : ''
      }${tempOpportunity.sale_id != 0 ? ',' + tempOpportunity.sale_id : ''}`"
    /> -->

    <MultiFilesTable
     listType="multi"
      s3Folder="opportunities"
      :moduleName="`opportunity`"
      :customerId="tempOpportunity.customer_id"
      :moduleId="`${tempOpportunity.id}`"
      :multiIds="[
        { module: 'opportunity', id: tempOpportunity.id },
        { module: 'contract', id: tempOpportunity.contract_id },
        { module: 'sale', id: tempOpportunity.sale_id },
      ]"
    />
  </div>
</template>

<script>
import MultiFilesTable from "@/components/general/MultiFilesTable.vue";

export default {
  name: "OptFilesTab",
  props: {
    tempOpportunity: Object,
    opportunityForm: Object,
  },
  components: {
    MultiFilesTable,
  },
  data() {
    return {
      activeCollapse: "",
    };
  },
  methods: {
    activeAccordeon(current) {
      if (current == this.activeCollapse) {
        this.activeCollapse = -1;
      } else {
        this.activeCollapse = current;
      }
    },
    emitUpdateTempOpportunity(tempOpportunity) {
      console.log("emitUpdateTempOpportunity 1", tempOpportunity);
      this.$emit("emitUpdateTempOpportunity", tempOpportunity);
    },
  },
};
</script>

<style lang="sass" scoped>
.collapse-row
  padding: 0 10px 0 0
  .content
    padding: 6px 3px 20px 20px
    .edit
      background: #f27935
      border-color: #f27935
      padding: 5px 10px
    .update
      background: #2ecc71
      border-color: #2ecc71
      padding: 5px 10px
  .head
    text-transform: capitalize
    background: #929496
    padding: 5px 8px
    border-radius: 5px
    margin-bottom: 5px
    color: #FFF
    text-transform: uppercase
    cursor: pointer
    &.done
      background: #2ecc71
</style>
