<template>
  <section>
    <div class="a-right" style="margin-top: -54px; margin-bottom: 31px">
      <aButton
        @click="generatePDF"
        size="small"
        type="danger"
        :loading="generatePDFLoading"
        ghost
      >
        <a-icon type="file-pdf" /> PDF
      </aButton>
    </div>

    <aRow :gutter="10">
      <aCol :span="6">
        <div class="travel-input">
          <label class="filled">Protocolos de atendimento</label>
          <div class="list-protocols">
            <div
              class="item c-pointer mt-5 f12"
              :class="
                wppConversations.filters.protocolNumber === '' ? 'active' : ''
              "
              @click="onSelectProtocol('')"
            >
              <b class="number">Todos protocolos </b>
            </div>
            <div
              v-for="(item, index) of wppConversations.protocols.list"
              :key="index"
              @click="onSelectProtocol(item.protocolnumber)"
              class="item c-pointer mt-5 f12"
              :class="
                wppConversations.filters.protocolNumber === item.protocolnumber
                  ? 'active'
                  : ''
              "
            >
              <div v-if="item.protocolnumber">
                <b class="number">
                  {{ item.protocolnumber }}
                </b>

                <div class="f10">
                  <aRow type="flex" justify="space-between">
                    <aCol>
                      Iniciado em:
                      {{ convertDateToBRT(item.date) | formatMultiDateTime }}
                    </aCol>

                    <aCol>
                      <!-- <a-icon type="message" /> -->
                    </aCol>
                  </aRow>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aCol>
      <aCol :span="18">
        <div
          id="wpp-chat"
          class="conversations"
          :class="generatePDFLoading ? 'print' : ''"
        >
          <aRow v-if="!wppConversations.loading">
            <aCol
              v-for="(message, index) in wppConversations.list"
              :key="index"
              :span="24"
            >
              <div
                class="message"
                :class="message.sentFrom === 'outgoing' ? 'right outgoing' : ''"
              >
                <aRow :gutter="10">
                  <aCol :span="23">
                    <div
                      class="text"
                      v-html="formatText(message.text, message.sentFrom)"
                    ></div>
                    <div class="date">
                      <span v-if="message.sentFrom === 'incoming'"
                        >Cliente</span
                      >
                      <span
                        class="white"
                        v-if="message.sentFrom === 'outgoing'"
                      >
                        <b>{{
                          message.sourcesender
                            ? message.sourcesender
                            : "Chatbot"
                        }}</b>
                      </span>

                      Via WhatsApp - {{ message.sourcecountrycode
                      }}{{ message.sourcephonenumber }} -
                      {{ convertDateToBRT(message.date) | formatMultiDateTime }}
                    </div></aCol
                  >
                  <aCol :span="1"
                    ><i class="ico fa-brands fa-whatsapp"></i
                  ></aCol>
                </aRow>
              </div>
            </aCol>
            <aCol
              class="a-center mt-30 mb-30"
              v-if="wppConversations.list.length === 0"
              :span="24"
            >
              Nenhuma conversa registrada para este número de telefone.
            </aCol>
          </aRow>

          <div v-if="wppConversations.loading">
            <a-skeleton class="custom-skeleton" />
            <a-skeleton class="custom-skeleton" />
            <a-skeleton class="custom-skeleton" />
          </div>

          <div v-if="wppConversations.list.length !== 0" class="a-center">
            <a-pagination
              show-size-changer
              :default-current="wppConversations.pagination.page"
              :page-size.sync="wppConversations.pagination.perPage"
              :page-size-options="pageSizeOptions"
              :total="wppConversations.pagination.total"
              @change="changePage"
              @showSizeChange="changePageSize"
            />
          </div></div
      ></aCol>
    </aRow>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "WhatsappConversations",
  props: {
    countryCode: String,
    phoneNumber: String,
    customerName: String,
  },
  mixins: [formatThings],
  data() {
    return {
      generatePDFLoading: false,
      pageSizeOptions: ["10", "20", "30", "40", "100", "200", "500", "1000"],
      wppConversations: {
        list: [],
        protocols: {
          list: [],
        },
        loading: false,
        filters: {
          order: "",
          protocolNumber: "",
        },
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
      },
    };
  },
  mounted() {
    this.getProtocols();
    this.getConversations();
  },
  methods: {
    generatePDF() {
      this.generatePDFLoading = true;

      let element = document.getElementById("wpp-chat");
      let options = {
        filename: `Conversas Whatsapp com ${
          this.customerName ?? "Cliente"
        }.pdf`,
        image: { type: "jpeg", quality: 1 },
        enableLinks: false,
        margin: [8, 8, 8, 8],
        html2canvas: { scale: 2 },
        jsPDF: {
          format: "a4",
          orientation: "portrait",
        },
        pagebreak: { mode: "avoid-all", after: ".avoidThisRow" },
      };

      setTimeout(() => {
        html2pdf().from(element).set(options).save();
        setTimeout(() => {
          this.generatePDFLoading = false;
        }, 100);
      }, 100);
    },
    getProtocols() {
      this.$integrations
        .get(
          `/integrations/robbu/protocols?order=DESC&orderBy=date&page=1&pageSize=100&sourcephonenumber=${this.formatPhoneNumber()}&destinationphonenumber=${this.formatPhoneNumber()}`
        )
        .then(({ data }) => {
          this.wppConversations.protocols.list = data.data;
        });
    },
    getConversations() {
      let filters = "";
      if (this.wppConversations.filters.protocolNumber)
        filters += `&protocolnumber=${this.wppConversations.filters.protocolNumber}`;
      this.wppConversations.loading = true;
      this.$integrations
        .get(
          `/integrations/robbu/messages?order=DESC&orderBy=date&page=${
            this.wppConversations.pagination.page
          }&pageSize=${
            this.wppConversations.pagination.perPage
          }&sourcephonenumber=${this.formatPhoneNumber()}&destinationphonenumber=${this.formatPhoneNumber()}${filters}`
        )
        .then(({ data }) => {
          this.wppConversations.list = data.data;
          this.wppConversations.pagination.page = data.meta.page;
          this.wppConversations.pagination.perPage = data.meta.perPage;
          this.wppConversations.pagination.total = data.meta.total;
        })
        .finally(() => {
          this.wppConversations.loading = false;
        });
    },
    onSelectProtocol(protocol) {
      this.wppConversations.filters.protocolNumber = protocol;
      this.getConversations();
    },
    changePage(current) {
      this.wppConversations.pagination.page = current;
      this.getConversations();
    },
    changePageSize(current, pageSize) {
      this.wppConversations.pagination.page = current;
      this.wppConversations.pagination.perPage = pageSize;
      this.getConversations();
    },
    formatText(text, sentFrom) {
      // Replace underscores with <br>
      text = text.replace(/_/g, "<br>");

      // Replace asterisks with <b> and </b>
      text = text.replace(/\*/g, function (match, offset, string) {
        // Check if it's an opening or closing asterisk
        return offset > 0 && string[offset - 1] !== " "
          ? "</b>"
          : "<b class='strong'>";
      });

      // Replace links with <a> tags
      text = text.replace(
        /(?:https?:\/\/\S+)|(www\.\S+)|(?:\S+\.(?:com|com\.br))\b/gi,
        function (match) {
          if (match.startsWith("http")) {
            return (
              '<a class="link" target="_blank" href="' +
              match +
              '">' +
              match +
              "</a>"
            );
          } else if (match.startsWith("www")) {
            return (
              '<a class="link" target="_blank" href="http://' +
              match +
              '">' +
              match +
              "</a>"
            );
          } else {
            return (
              '<a class="link" target="_blank" href="http://' +
              match +
              '">' +
              match +
              "</a>"
            );
          }
        }
      );

      // Replace ???? with [emoji]
      text = text.replace(/\?\?\?\?/g, '<span class="emoji tag">Emoji</span>');
      text = text.replace(/\?\?/g, '<span class="emoji tag"> Emoji</span>');

      if (sentFrom === "outgoing" && text == "")
        text =
          '<span class="options tag">Opções para o cliente escolher</span>';

      return text;
    },
    convertDateToBRT(dateStr) {
      var dateObj = new Date(dateStr);

      // Subtract 3 hours
      dateObj.setHours(dateObj.getHours() - 3);

      // Format the new date back to string
      var newDateStr = dateObj.toISOString();

      return newDateStr;
    },
    formatPhoneNumber() {
      return this.phoneNumber
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace(" ", "")
        .replace(" ", "")
        .replace("-", "");
    },
  },
};
</script>

<style lang="sass">
.conversations
  .custom-skeleton
    ul li, h3
      background-color: #afbed2
      opacity: 0.4
  .message
    .tag
      padding: 0px 5px
      font-weight: 600
      border: 1px solid
      border-radius: 5px
      font-size: 12px
      margin-bottom: 5px
      display: inline-block
    .tag.emoji
      color: #6f7e92
      background: #ffffff
      border-color: #aaa
    .tag.options
      padding: 2px 5px
      color: #6f7e92
      background: #ffffff
      border-color: #ddd
  .message.outgoing
    .strong
      color: #FFF
    .link
      color: #fadb14
</style>

<style lang="sass" scoped>
.list-protocols
  .item
    padding: 10px
    border: 1px solid #ddd
    border-radius: 10px
  .active
    background: #eff2f7
    border: 1px solid #bbb
    .number
      color: #BE418E

.conversations.print
  max-height: unset
  overflow: unset

.conversations
  border-radius: 10px
  background: #eff2f7
  padding: 20px
  max-height: 600px
  overflow: auto
  .message:before
    left: -12px
    border-color: transparent #fff transparent transparent
    border-width: 0 19px 19px 0
    display: block
    content: ""
    position: absolute
    width: 0
    height: 0
    border-style: solid
    top: 0
  .message.outgoing
    float: right
    background: #6f7e92
    border-radius: 10px 0px 10px 10px
    .text
      color: #fff
    .link
      color: #fff !important
    .strong
      color: #fff !important
    .date
      color: hsla(0,0%,100%,.8)
    .ico
      color: hsla(0,0%,100%,.8)
  .message.outgoing:before
    right: -12px
    border-color: transparent transparent transparent #6f7e92
    border-width: 0 0 19px 13px
    left: unset
  .message
    position: relative
    display: inline-block
    margin-bottom: 20px
    max-width: 70%
    background: #fff
    padding: 20px
    border-radius: 10px
    .text
      font-size: 14px
      color: #666
      line-height: 1.4
      .strong
        color: #000
    .date
      color: #bbb
      font-size: 11px
      margin-top: 8px
      text-align: right
    .ico
      color: #bbb
      font-size: 20px
</style>
