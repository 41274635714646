<template>
  <div class="mt-10">
    <a-row class="f10 header" :gutter="20">
      <a-col :span="14"> EMAILS </a-col>
      <a-col :span="5"> PRINCIPAL </a-col>
      <a-col :span="3"> </a-col>
    </a-row>

    <a-radio-group
      v-model="theMainEmail"
      @change="onSelectMainEmail"
      style="width: 100%"
    >
      <a-row
        class="extra-fields"
        v-for="(item, index) in extraEmailsRows"
        :key="index"
        :gutter="20"
        :class="item.id == 1 ? 'none' : ''"
      >
        <a-col :span="14">
          <a-form-item>
            <a-input
              class="travel-input"
              :class="item.id == 1 ? 'readonly none' : ''"
              placeholder="Escreva..."
              @blur="verifyEmailAddress(item.id)"
              v-decorator="[
                `extra_email_${item.id}`,
                {
                  initialValue: theEmailInitialValue(item.id),

                  rules: [
                    {
                      type: `email`,
                      message: 'Escreva um e-mail válido',
                    },
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon class="grayscale" type="mail" slot="prefix" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :class="item.id == 1 ? 'readonly none' : ''" :span="5">
          <a-radio :value="item.id"></a-radio>
        </a-col>

        <a-col :span="3">
          <a-button
            v-if="item.id != 1 && item.id == extraEmailsRows.length"
            class="delete"
            size="small"
            type="danger"
            shape="circle"
            icon="delete"
            @click="removeEmailRow(item.id)"
          >
          </a-button>
        </a-col>
      </a-row>
    </a-radio-group>

    <a-row class="mb-30">
      <a-col class="a-center" :span="24">
        <a-button
          class="add-button color f12"
          size="small"
          @click="addEmailRow"
        >
          Adicionar e-mail
        </a-button>
      </a-col>
    </a-row>

    <a-divider />

    <a-row class="f10 header" :gutter="20">
      <a-col :span="4"> TIPO </a-col>
      <a-col :span="9"> NÚMEROS </a-col>
      <a-col :span="5"> PRINCIPAL </a-col>
      <a-col :span="3"> </a-col>
    </a-row>

    <a-radio-group
      v-model="theMainPhone"
      @change="onSelectMainPhone"
      style="width: 100%"
    >
      <a-row
        class="extra-fields"
        v-for="(item, index) in extraPhonesRows"
        :key="index"
        :gutter="20"
        :class="thePhoneNumberClass(item.id)"
      >
        <a-col :span="4">
          <a-form-item>
            <a-select
              class="travel-input"
              :class="thePhoneNumberClass(item.id)"
              show-search
              placeholder="Tel/Cel"
              :style="'width: 100%'"
              v-decorator="[
                `extra_phone_type_${item.id}`,
                {
                  initialValue: thePhoneTypeInitialValue(item.id),
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-select-option
                v-for="(item, index) of phonesType"
                :key="index"
                :value="item.name"
              >
                <a-icon :type="item.ico" />
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="9">
          <a-form-item>
            <a-input
              class="travel-input"
              :class="thePhoneNumberClass(item.id)"
              placeholder="Escreva..."
              v-mask="
                customerForm.getFieldValue(`extra_phone_type_${item.id}`) ==
                'Mobile'
                  ? '(##) # ####-####'
                  : '(##) ####-####'
              "
              @blur="verifyPhone(item.id)"
              v-decorator="[
                `extra_phone_${item.id}`,
                {
                  initialValue: thePhoneNumberInitialValue(item.id),
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="5">
          <a-radio
            :class="thePhoneNumberClass(item.id)"
            :value="item.id"
          ></a-radio>
        </a-col>

        <a-col :span="3">
          <a-button
            v-if="item.id != 1 && item.id == extraPhonesRows.length"
            class="delete"
            size="small"
            type="danger"
            shape="circle"
            icon="delete"
            @click="removePhoneRow(item.id)"
          >
          </a-button>
        </a-col>
      </a-row>
    </a-radio-group>

    <a-row class="mb-30">
      <a-col class="a-center" :span="24">
        <a-button
          class="add-button color f12"
          size="small"
          @click="addPhoneRow"
        >
          Adicionar telefone
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "CustomerFormExtraContacts",
  props: {
    customerForm: Object,
    customer: Object,
    newFieldSizes: Object,
    disableCustomerMainData: Boolean,
  },
  data() {
    return {
      theMainEmail: 1,
      theMainPhone: 1,
      phonesType: [
        {
          name: "Phone",
          ico: "phone",
        },
        {
          name: "Mobile",
          ico: "mobile",
        },
      ],
      extraEmailsRows: [{ id: 1 }],
      extraPhonesRows: [{ id: 1 }],
    };
  },
  mounted() {
    this.setCustomerFields();
  },
  methods: {
    setCustomerFields() {
      setTimeout(() => {
        this.extraEmailsRows = this.customer.extra_emails_rows
          ? JSON.parse(this.customer.extra_emails_rows)
          : [{ id: 1 }];

        this.extraPhonesRows = this.customer.extra_phones_rows
          ? JSON.parse(this.customer.extra_phones_rows)
          : this.customer.phone
          ? [{ id: 1 }, { id: 2 }]
          : [{ id: 1 }];

        this.theMainEmail = this.customer.main_email
          ? parseInt(this.customer.main_email)
          : 1;

        this.theMainPhone = this.customer.main_phone
          ? parseInt(this.customer.main_phone)
          : 1;

        setTimeout(() => {
          this.extraEmailsRows.forEach((row) => {
            if (row.id != 1) {
              this.customerForm.setFieldsValue({
                [`extra_email_${row.id}`]:
                  this.customer[`extra_email_${row.id}`],
              });
            }
          });

          this.extraPhonesRows.forEach((row) => {
            if (row.id != 1) {
              this.customerForm.setFieldsValue({
                [`extra_phone_${row.id}`]:
                  this.customer[`extra_phone_${row.id}`],
                [`extra_phone_type_${row.id}`]:
                  this.customer[`extra_phone_type_${row.id}`],
              });
            }

            if (row.id == 2 && this.customer.phone) {
              this.customerForm.setFieldsValue({
                [`extra_phone_${row.id}`]: this.customer.phone,
              });
            }
          });
        }, 100);
      }, 100);
    },
    verifyEmailAddress(id) {
      let verifyData = {
        customer_id: this.customer.id,
        what_to_verify: "email",
        value_to_verify: this.customerForm.getFieldValue("extra_email_" + id),
      };

      this.$http
        .post("/customer/verifications", verifyData)
        .then(({ data }) => {
          data;
        })
        .catch(({ response }) => {
          response;
          this.$message.warning(response.data.message);
          this.customerForm.setFieldsValue({
            [`extra_email_${id}`]: undefined,
          });
        });
    },
    verifyPhone(id) {
      let verifyData = {
        customer_id: this.customer.id,
        what_to_verify: "phones",
        value_to_verify: this.customerForm.getFieldValue("extra_phone_" + id),
      };

      this.$http
        .post("/customer/verifications", verifyData)
        .then(({ data }) => {
          data;
        })
        .catch(({ response }) => {
          response;
          this.$message.warning(response.data.message);
          this.customerForm.setFieldsValue({
            [`extra_phone_${id}`]: undefined,
          });
        });
    },
    onSelectMainEmail(e) {
      this.customer.main_email = e.target.value;
    },
    onSelectMainPhone(e) {
      this.customer.main_phone = e.target.value;
    },
    addEmailRow() {
      this.extraEmailsRows.push({
        id: this.extraEmailsRows.length + 1,
      });

      this.customer.extra_emails_rows = JSON.stringify(this.extraEmailsRows);
    },
    removeEmailRow(id) {
      for (var i in this.extraEmailsRows) {
        if (this.extraEmailsRows[i].id == id) {
          this.extraEmailsRows.splice(i, 1);
          break;
        }
      }
      this.customer.extra_emails_rows = JSON.stringify(this.extraEmailsRows);
    },
    addPhoneRow() {
      this.extraPhonesRows.push({
        id: this.extraPhonesRows.length + 1,
      });

      this.customer.extra_phones_rows = JSON.stringify(this.extraPhonesRows);
    },
    removePhoneRow(id) {
      for (var i in this.extraPhonesRows) {
        if (this.extraPhonesRows[i].id == id) {
          this.extraPhonesRows.splice(i, 1);
          break;
        }
      }
      this.customer.extra_phones_rows = JSON.stringify(this.extraPhonesRows);
    },
    theEmailInitialValue(id) {
      let theValue = undefined;
      if (id == 1) {
        theValue =
          this.customer["email"] != undefined
            ? this.customer.email
            : this.customerForm.getFieldValue("email");
      }

      return theValue;
    },
    thePhoneTypeInitialValue(id) {
      let theValue = undefined;
      if (id == 1) {
        theValue = "Mobile";
      }

      if (id == 2) {
        theValue =
          this.customer.phone || this.customerForm.getFieldValue("phone")
            ? "Phone"
            : undefined;
      }

      return theValue;
    },
    thePhoneNumberClass(id) {
      let theClass = "";
      if (id == 1) {
        theClass = "readonly none";
      }

      if (id == 2) {
        theClass =
          this.customer.phone || this.customerForm.getFieldValue("phone")
            ? "readonly none"
            : "";
      }

      return theClass;
    },
    thePhoneNumberInitialValue(id) {
      let theValue = undefined;
      if (id == 1) {
        theValue = this.customer.mobile_phone
          ? this.customer.mobile_phone
          : this.customerForm.getFieldValue("mobile_phone");
      }

      if (id == 2) {
        theValue = this.customer.phone
          ? this.customer.phone
          : this.customerForm.getFieldValue("phone");
      }

      return theValue;
    },
  },
};
</script>

<style lang="sass" scoped></style>
