<template>
  <div>
    <div class="w-box">
      <a-form-item class="travel-input-outer mt-15">
        <label for class="filled"> ID do contrato </label>
        <a-input
          class="travel-input"
          :class="$root.isAdmin() ? '' : 'readonly'"
          type="number"
          placeholder="Insira"
          v-decorator="[
            `contract_id`,
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <template #addonAfter
            ><a-icon v-if="loadingContracts" type="loading"
          /></template>
        </a-input>
      </a-form-item>

      <a-form-item
        v-show="tempOpportunity.contract_id != 0"
        class="travel-input-outer mt-15"
      >
        <label for class="filled"> ID da venda </label>
        <a-input
          class="travel-input"
          :class="$root.isAdmin() ? '' : 'readonly'"
          type="number"
          placeholder="Insira"
          v-decorator="[
            `sale_id`,
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <template #addonAfter
            ><a-icon v-if="loadingSales" type="loading"
          /></template>
        </a-input>
      </a-form-item>

      <a-form-item class="travel-input-outer mt-15">
        <label for :class="'filled'"> Responsável pela oportunidade </label>
        <a-select
          class="travel-input"
          :class="$root.isAdmin() ? '' : 'readonly'"
          placeholder="Selecione"
          optionFilterProp="txt"
          show-search
          @change="onChangeUser"
          v-decorator="[
            `user_id`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
          style="width: 100%"
        >
          <a-select-option
            v-for="(item, index) of listUsers(user.list)"
            :key="index"
            :value="item.id"
            :txt="item.first_name"
            :user="item"
          >
            <div v-if="item.id == 0">{{ item.first_name }}</div>
            <div v-else>
              {{ item.id }} - {{ item.first_name }} {{ item.last_name }}
            </div>
          </a-select-option>
        </a-select>
      </a-form-item>
    </div>

    <div class="submenu">
      <a-row>
        <a-col v-for="(item, index) in submenu" :key="index" :span="24">
          <div
            v-if="item.showItem"
            class="row border c-pointer hover"
            :class="`${item.extraClass} ${
              item.disabled ? 'disabled grayscale' : ''
            }`"
            @click="activeTab(item.name)"
          >
            <span class="txt">
              <a-row type="flex" justify="space-between">
                <a-col>
                  <span class="img">
                    <img :src="item.img" :alt="item.name" width="15" />
                  </span>
                  {{ item.name }}
                </a-col>
                <a-col> </a-col>
              </a-row>
            </span>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import userMixins from "@/mixins/user/userMixins.js";

// images
import login from "@/assets/images/dashboard/customers/login-data.png";

export default {
  name: "OptMenuSection",
  props: {
    tempOpportunity: Object,
    opportunityForm: Object,
    opportunity: Object,
  },
  mixins: [formatThings, userMixins],
  data() {
    return {
      openCustomerDataModal: false,
      openNotesModal: false,
      openLogsModal: false,
      loadingContracts: false,
      loadingSales: false,
      userBeforeChange: {},
      noneOption: [
        {
          id: "0",
          first_name: "Ninguém",
          last_name: "",
        },
      ],
      submenu: [
        {
          name: "JSON da oportunidade",
          img: login,
          ico: "",
          showItem: true,
          disabled: false,
          action: false,
          extraClass: " no-border",
        },
      ],
    };
  },
  mounted() {
    this.user.pagination.perPage = 500;
    this.getUsers();

    setTimeout(() => {
      this.opportunityForm.setFieldsValue({
        user_id:
          this.tempOpportunity.user_id != 0
            ? this.tempOpportunity.user_id
            : undefined,
        contract_id:
          this.tempOpportunity.contract_id != 0
            ? this.tempOpportunity.contract_id
            : undefined,
        sale_id:
          this.tempOpportunity.sale_id != 0
            ? this.tempOpportunity.sale_id
            : undefined,
      });

      if (this.tempOpportunity.contract_id == 0) this.getContractByOptId();
      if (
        this.tempOpportunity.contract_id != 0 &&
        this.tempOpportunity.sale_id == 0
      )
        this.getSaleByOptId();
    }, 500);
  },
  methods: {
    getContractByOptId() {
      this.loadingContracts = true;
      this.$http
        .post(
          `/contract-v2/list?page=1&per_page=1&opportunity_id=${this.tempOpportunity.id}&order=desc&order-by=created`
        )
        .then(({ data }) => {
          this.opportunityForm.setFieldsValue({
            contract_id: data.data[0].id,
          });

          this.tempOpportunity.contract_id = data.data[0].id;

          this.$http.post("/opportunity/update-field", {
            id: this.tempOpportunity.id,
            field: "contract_id",
            value: data.data[0].id,
          });
        })
        .finally(() => {
          this.loadingContracts = false;
        });
    },
    updateSaleId(id) {
      this.opportunityForm.setFieldsValue({
        sale_id: id,
      });

      this.tempOpportunity.sale_id = id;

      this.$http
        .post("/opportunity/update-field", {
          id: this.tempOpportunity.id,
          field: "sale_id",
          value: id,
        })
        .then(() => {
          this.$message.success("ID da venda atualizado!", 5);
        });
    },
    getSaleByOptId() {
      this.loadingSales = true;
      this.$http
        .post(
          `/sale/list?page=1&per_page=1&contract-id=${this.tempOpportunity.contract_id}&order=desc&order-by=created`
        )
        .then(({ data }) => {
          this.updateSaleId(data.data[0].id);
        })
        .finally(() => {
          this.loadingSales = false;
        });
    },
    activeTab(tab) {
      this.$emit("activeTab", tab);
    },
    listUsers(listUsers) {
      return this.noneOption.concat(listUsers);
    },
    onChangeUser(id, user) {
      const theUser = user.data.attrs.user;

      if (
        theUser.id != this.tempOpportunity.user_id &&
        this.tempOpportunity.user_id != 0
      ) {
        this.userBeforeChange = this.tempOpportunity.user;
      }

      if (id != 0) {
        this.$http
          .post("/opportunity/update-field", {
            id: this.tempOpportunity.id,
            field: "user_id",
            value: id,
          })
          .then(() => {
            this.sendNotificationOnChangeUser(id, theUser);
          });
      } else {
        this.$message.success(
          `Alteração de responsável realizada com sucesso!`,
          4
        );

        this.$http.post("/opportunity/update-field", {
          id: this.tempOpportunity.id,
          field: "user_id",
          value: 0,
        });
        this.$http.post("/log/create", {
          user_id: this.$store.state.userData.id,
          module_id: this.tempOpportunity.id,
          module: "opportunity",
          action: "change-opportunity-user",
          description: `Esta oportunidade que pertencia a <b>${
            this.userBeforeChange.first_name != undefined
              ? this.userBeforeChange.first_name +
                " " +
                this.userBeforeChange.last_name
              : "Ninguém"
          } </b> agora pertence a  <b>Ninguém</b>, alteração realizada por  <b>${
            this.$store.state.userData.first_name
          } ${this.$store.state.userData.last_name} </b>.`,
        });
      }
    },
    sendNotificationOnChangeUser(userId, user) {
      this.tempOpportunity.user = user;

      this.$http
        .post("/notification/create", {
          user_id: userId,
          created_by: this.$store.state.userData.id,
          title: `Nova oportunidade recebida!`,
          content: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} encaminhou a oportunidade ID ${this.tempOpportunity.id} pra você. Para ver detalhes clique nesta notificação.`,
          action: `/opportunity/edit/${this.tempOpportunity.id}`,
          is_read: 0,
        })
        .then(() => {
          this.$message.success(
            `Uma notificação foi enviada para ${user.first_name} ${user.last_name} avisando da alteração de responsável por esta oportunidade.`,
            4
          );

          // atualiza a data
          this.$http.post("/opportunity/update-field", {
            id: this.tempOpportunity.id,
            field: "received_at",
            value: new Date(),
          });

          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempOpportunity.id,
            module: "opportunity",
            action: "change-opportunity-user",
            description: `Esta oportunidade que pertencia a <b>${
              this.userBeforeChange.first_name != undefined
                ? this.userBeforeChange.first_name +
                  " " +
                  this.userBeforeChange.last_name
                : "Ninguém"
            } </b> agora pertence a  <b>${user.first_name} ${
              user.last_name
            } </b>, alteração realizada por  <b>${
              this.$store.state.userData.first_name
            } ${this.$store.state.userData.last_name} </b>.`,
          });
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.submenu
  padding: 15px !important
.row
  padding: 5px 10px
  font-size: 13px
  font-weight: 500
  width: 314px
  transition: .3s
  max-width: 100%
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  .img
    width: 20px
    display: inline-block
    -webkit-filter: grayscale(1)
    -webkit-filter: grayscale(100%)

  .ant-tag
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    display: inline-block
    max-width: 100% !important
    float: left
    text-transform: uppercase
    font-size: 10px
    font-weight: 600
    margin-bottom: 6px !important
  &.hover:hover
    background: rgba(0,0,0,0.02)
    color: #ff8000
    .img
        -webkit-filter: grayscale(0)
        -webkit-filter: grayscale(0%)
  &.border
    border-bottom: 1px solid #d6d6d6
    padding: 10px 5px
  &.disabled
    .txt
      opacity: 0.3
  &::last-child
    border-bottom: 0
  &.no-border
    border: 0
    padding: 10px  5px
  &.bold
    font-weight: 600
</style>
